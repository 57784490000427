<template>
  <div class="company-bg">
    <b-container>
      <div class="row company-section">
        <div class="col-12 col-md-7 p-2">
          <p class="company-title mt-3 mx-auto"  data-aos="fade-up">
            We provide Technology Solutions
          </p>

          <p class="h3 company-description mt-3"  data-aos="fade-up"  data-aos-delay="200">
            We provide cutting-edge technology solutions tailored to your needs,
            empowering businesses to thrive in the digital age.
          </p>

          <a class="btn get-demo-btn my-3" href="/about-us"  data-aos="fade-up"  data-aos-delay="250">
            <span class="p-5">Contact Us </span>
          </a>
        </div>
        <div class="btn btn-lg col-12 col-md-5"  data-aos="fade-up" >
          <b-img
            src="/img/TopImage.png"
            class="img-fluid company-page-image"
          ></b-img>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  mounted() {
    AOS.init();
  },
};
</script>

<style scoped>
.company-title {
  font-style: normal;
  font-weight: 800;
  font-size: clamp(33px, 4vw, 57px);
  line-height: clamp(35px, 4vw, 73px);
  /* or 113% */

  color: #33495c;

  backdrop-filter: blur(1.62379px);
}

.company-description {
  font-style: normal;
  font-weight: 400;
  font-size: clamp(18px, 4vw, 25px);
  line-height: clamp(25px, 4vw, 30px);
  font-family: Roboto;
  color: #887c8f;
}

.get-demo-btn {
  background: linear-gradient(99.13deg, #1088d6 6.21%, #1256d3 91.79%),
    linear-gradient(122.29deg, #13e3b1 14.46%, #0c94b1 78.74%),
    linear-gradient(113.74deg, #f9ab7d 27.92%, #f95e70 69.53%);
  border-radius: 100px;
  color: #fff;
  box-shadow: -2px -4px 0px 0px #00000040 inset;
  max-width: 200px;
  text-align: center;
  font-size: 18px;
  padding: 10px 0px;
  font-weight: 600;
  width: 100%;
  
}

.company-bg {
  background-image: url(/img/Background.png);
  background-size: cover;
  background-position: center center;
  padding: 15px;
}

.company-page-image {
  width: 100%;
}

.company-section {
  padding: 30px;
  align-items: center;
}

@media only screen and (max-width: 490px) {
  .company-section {
    padding: 30px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .get-demo-btn {
    max-width: 100%;
  }

  .company-title {
    text-align: center;
  }

  .company-description {
    text-align: justify;
  }
}

@media only screen and (max-width: 1000px) {
}
</style>
